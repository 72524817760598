export const colors = {
  Navy: `#15396c`,
  Gold: `#fda528`,
  Gray: `#2b2b2b`,
  lightGray: `#e5e5e5`,
  softBlue: `#636783`,
  darkGold: `#da850b`,
  White: `#ffffff`,
  Black: `#000000`
}
export const textMont = `font-family:'Montserrat', sans-serif;`

export const transDefault = 'transition:all 0.2s ease-in-out'
export const transFunction = (
  property = 'all',
  time = '0.2s',
  type = 'linear'
) => {
  return `transition:${property} ${time} ${type}`
}
export const transObject = ({
  property = 'all',
  time = '0.4s',
  type = 'ease-in-out'
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const transition = ({
  property = 'all',
  time = '0.15s',
  type = 'ease-in-out'
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const border = ({
  width = '0.1rem',
  type = 'solid',
  color = 'white'
}) => {
  return `border:${width} ${type} ${color}`
}

export const letterSpacing = ({ spacing = '0.1rem' }) => {
  return `letter-spacing:${spacing}`
}
