import React from 'react'
import styled from 'styled-components'
import { styles } from '../utils'

export function Title({ title, message }) {
  return (
    <TitleWrapper>
      <h1 className='title'>{title}</h1>
      <div className='underline' />
    </TitleWrapper>
  )
}

Title.defaultProps = {
  title: 'our title'
}
const TitleWrapper = styled.div`
  text-align: center;
  .message {
    ${styles.letterSpacing({ spacing: '0.1rem' })};
    font-size: 1.2rem;
    font-weight: 400;
    color: ${styles.colors.Navy};
  }
  .title {
    ${styles.letterSpacing({ spacing: '0.1rem' })};
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${styles.colors.Gray};
    margin-top: 1rem;
  }
  .underline {
    width: 50%;
    height: 0.1rem;
    background: ${styles.colors.Gold};
    margin: 0.5rem auto;
  }
`
