import React, { Component } from 'react'
import styled from 'styled-components'
import { styles } from '../../../utils'

export default class NavbarContact extends Component {
  render() {
    return (
      <NumberWrapper>
        <div className='phone'>
          <p>251-209-1992</p>
        </div>
      </NumberWrapper>
    )
  }
}

const NumberWrapper = styled.div`
height: 10px;
    color: ${styles.colors.White};
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: ${styles.colors.softBlue};
    }
  }

  .phone {
    display: none;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0.5rem 1rem;
      display: block;
      text-align: right;
    }

`
