import React, { Component } from 'react'
import styled from 'styled-components'
import { styles } from '../../utils'

export default class Footer extends Component {
  state = {
    // icons: [
    //   {
    //     id: 1,
    //     icon: <FaFacebook className='icon facebook-icon' />,
    //     path: 'https://www.facebook.com'
    //   },
    //   {
    //     id: 2,
    //     icon: <FaInstagram className='icon instagram-icon' />,
    //     path: 'https://www.instagram.com'
    //   },
    //   {
    //     id: 3,
    //     icon: <FaGooglePlusG className='icon google-icon' />,
    //     path: 'https://www.google.com'
    //   }
    // ]
  }
  render() {
    return (
      <FooterWrapper>
        <div className='title'>S.A. Walley Marine Services</div>
        <p className='copyright'>
          Copyright &copy; 2019 S.A. Walley Marine Services
        </p>
      </FooterWrapper>
    )
  }
}
const FooterWrapper = styled.footer`
  padding: 2rem 0;
  background: ${styles.colors.Navy};
  /* .icons {
    width: 10rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .icon {
    color: ${styles.colors.White};
    font-size: 1.3rem;
    ${styles.transition({})};
    &:hover {
      color: ${styles.colors.Gold};
    }
  } */
  .copyright {
    color: ${styles.colors.softBlue};
    font-size: 0.9rem;
    text-transform: capitalize;
    text-align: center;
    margin: 1rem 0 0.5rem 0;
  }
  .title {
    text-align: center;
    width: 20rem;
    color: ${styles.colors.White};
    text-transform: uppercase;
    padding: 0.3rem 1rem;
    margin: 0 auto 1rem auto;
    font-size: 1rem;
  }
`
