import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: - Walley Marine Surveying' />
    <h1>NOT FOUND</h1>
    <p>
      Oops! You got lost! Check your coordinates and reroute using the menu!
    </p>
  </Layout>
)

export default NotFoundPage
