import styled from 'styled-components'
import { styles } from '../utils'

const BannerButton = styled.button`
  display: block;
  color: #fff;
  background-color: ${styles.colors.Gold};
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  ${styles.border({ color: `${styles.colors.darkGold}` })};
  border-radius: 0.1rem;
  margin-bottom: 1rem;
  ${styles.transition({})};
  &:hover {
    background: ${styles.colors.darkGold};
    cursor: pointer;
    text-decoration: none !important;
  }
  a {
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }
`
const SectionButton = styled(BannerButton)`
  color: ${styles.colors.White};
  background-color: ${styles.colors.Navy};
  ${styles.border({ color: `${styles.colors.Black}` })};
  border-radius: 0.1rem;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    background: ${styles.colors.softBlue};
    ${styles.border({ color: `${styles.colors.Navy}` })};
    text-decoration: none !important;
  }
  a {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
`

export { BannerButton, SectionButton }
