import React, { Component } from 'react'
import { Link } from 'gatsby'
import logo from '../../../images/wms-nav.png'
import { FaAlignRight } from 'react-icons/fa'
import styled from 'styled-components'
import { styles } from '../../../utils'

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props
    return (
      <HeaderWrapper>
        <Link to='/'>
          <img src={logo} alt='WMS logo' />
        </Link>
        <FaAlignRight
          className='toggle-icon'
          onClick={() => {
            handleNavbar()
          }}
        />
      </HeaderWrapper>
    )
  }
}

const HeaderWrapper = styled.div`
  background: ${styles.colors.Navy};
  padding: 0.4rem 1rem;
  display: block;
  align-items: center;
  /* justify-content: space-between; */
  .toggle-icon {
    font-size: 1.8rem;
    color: #fff;
    cursor: pointer;
    float: right;
    margin: 3rem 0;
  }
  img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    .toggle-icon {
      display: none;
    }
    /* padding: 0.4rem 1rem; */
  }
`
