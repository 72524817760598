import React, { Component } from 'react'
import styled from 'styled-components'
import { styles } from '../../../utils'
import { FaInstagram, FaGooglePlusG, FaFacebook } from 'react-icons/fa'

export default class NavbarIcons extends Component {
  state = {
    icons: [
      {
        id: 1,
        icon: <FaFacebook className='icon facebook-icon' />,
        path: 'https://www.facebook.com'
      },
      {
        id: 2,
        icon: <FaInstagram className='icon instagram-icon' />,
        path: 'https://www.instagram.com'
      },
      {
        id: 3,
        icon: <FaGooglePlusG className='icon google-icon' />,
        path: 'https://www.google.com'
      }
    ]
  }
  render() {
    return (
      <IconWrapper>
        {this.state.icons.map(item => {
          return (
            <a
              href={item.path}
              key={item.id}
              target='_blank'
              rel='noopener noreferrer'
            >
              {item.icon}
            </a>
          )
        })}
      </IconWrapper>
    )
  }
}

const IconWrapper = styled.div`
  .icon {
    display: none;
    font-size: 1.3rem;
    margin: 0.4rem;
    cursor: pointer;
    ${styles.transFunction()};
  }
  .facebook-icon {
    color: #3b579d;
  }
  .google-icon {
    color: #dd4b39;
  }
  .instagram-icon {
    color: #9b6954;
  }
  .icon:hover {
    color: ${styles.colors.Navy};
  }
  display: none;
  @media (min-width: 768px) {
    display: flex;
    width: 10rem;
  }
`
